import { useEffect } from 'react'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import CloseIcon from 'public/icons/close.svg'
import Button from 'components/Button'
import style from './Modal.style'

const ModalStyled = styled.div(props => ({ ...style(props) }))

const Modal = ({ t, children, close, hasLargerCloseButton, bodyScroll, hasAnotherModal }) => {
    const closeOnEsc = e => {
        const keys = {
            27: () => {
                e.preventDefault()
                close()
                window.removeEventListener('keyup', closeOnEsc, false)
            }
        }

        if (keys[e.keyCode]) {
            keys[e.keyCode]()
        }
    }

    window.addEventListener('keyup', closeOnEsc, false)

    useEffect(() => {
        disableBodyScroll()

        window.ontouchmove = e => {
            e.preventDefault()
        }

        return function cleanup() {
            enableBodyScroll()

            window.ontouchmove = e => {
                return true
            }
        }
    })

    return (
        <ModalStyled>
            {hasLargerCloseButton ? (
                <div className="closeButton">
                    <Button onClick={close} variation="tertiary rounded" hasIcon>
                        {t('exit-gallery')} <CloseIcon />
                    </Button>
                </div>
            ) : (
                <button type="button" className="closeButton" onClick={close}>
                    <CloseIcon />
                </button>
            )}
            <div className="inner">
                <div className="Modal-content">{children}</div>
            </div>
        </ModalStyled>
    )
}

Modal.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    close: PropTypes.func.isRequired,
    bodyScroll: PropTypes.bool,
    hasAnotherModal: PropTypes.bool,
    hasLargerCloseButton: PropTypes.bool
}

Modal.defaultProps = {
    bodyScroll: false,
    hasAnotherModal: false,
    hasLargerCloseButton: false
}

export default withTranslation('common')(withTheme(Modal))
